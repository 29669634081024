// 79909
@import '../helpers/componentHeader';
// 58168
// 105364
.storepage-tilescard {
    .linebar-heading {
        text-align: center;
        width: auto;
        margin: 0;            
        font-family: $secondary-font-medium;
        font-size: 18px;
        line-height: 19px;
        color: $primary-darkest;
        font-weight: normal;
        letter-spacing: 0;
        @include media-breakpoint-up(md) {
            text-align: center;
            font-size: 36px;
            line-height: 46px;
        }
        
        &:after{
            content:'';
            height: 2px;
            width:64px;
            background: $yellow;
            margin-top:10px;
            margin-bottom: 40px;
            margin-right: auto;
            margin-left: auto;
            display: block;
            @include media-breakpoint-up(md) {
                margin-top:16px;
                margin-bottom: 60px;
            }
            @include media-breakpoint-down(sm){
                margin-bottom: 32px;
            }
        }
    }
    // 69356
    .card {
        .card-body {
            padding: 0px 22px 44px;
            .photo-tile-figure {
                margin-bottom: 30px;
            }
            .card-title {
                font-family: $primary-font-bold;
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                color: $night-rider;
                margin-bottom: 20px;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    line-height: 18px
                }
            }
            .card-text {
                font-family: $primary-font;
                font-size: 16px;
                line-height: 26px;
                color: $bright-gray;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    line-height: 18px
                }
            }
        }
    }
}
//58168